<template>
    <div id="app">
        <v-app>
            <!-- LOGIN FORM -->
            <img
                class="form-img"
                src="@/assets/dried-river-feature-img-4.webp"
            />
            <div class="right">
                <v-alert
                    v-model="error"
                    color="warning"
                    class="font-weight-black white--text"
                    dismissible
                >
                    {{ errorMessage }}
                </v-alert>
                <div class="content">
                    <img
                        :src="screenWidth > 860 ? require('@/assets/logoHorTransp.png') : require('@/assets/logoHor.png')"
                        :width="screenWidth > 1620 ? '100%' : '60%'"
                        :max-width="400"
                    />
                    <!-- <h1 id="main-title" class="pb-16 mx-md-15 mx-sm-15 logo">RiverTemp</h1> -->
                    <h2
                        id="title"
                        class="display-1"
                    >Login</h2>
                    <form
                        id="form-login"
                        class=""
                        @submit.prevent="login"
                    >
                        <email-field
                            class="ma-0"
                            :dark="screenWidth > 860 ? false : true"
                            @email-input="email = $event"
                            @email-valid="validEmail = $event"
                        ></email-field>
                        <password-field
                            class="ma-0 pa-0"
                            :dark="screenWidth > 860 ? false : true"
                            @password-input="password = $event"
                            @password-valid="validPassword = $event"
                        >
                            <template #password-label>Password</template>
                        </password-field>
                        <div class="ml-auto d-flex justify-end">
                        <router-link
                                to="/forgot-password"
                                class="text-capitalize forgotten-text">
                                    Forgot password?
                            </router-link>
                        </div>
                        <div class="form-element my-5">
                            <v-btn
                                id="logIn"
                                color="primary"
                                class="login text-capitalize white--text mr-4"
                                :disabled="invalidForm"
                                type="submit"
                                name="login"
                                large
                            >
                                Log In
                            </v-btn>
                            <v-btn
                                v-if="hideForFirstRelease"
                                id="goRight"
                                color="transparent"
                                class="login off text-capitalize"
                                name="signup"
                                @click="$router.push('/signup')"
                            >
                                Sign Up
                            </v-btn>
                        </div>
                    </form>

                    <!-- Partners -->
                    <div class="d-flex flex-row flex-wrap justify-space-between mt-6">
                        <v-img
                            v-for="(partner, index) in partnerLogo"
                            :key="index"
                            :src="partner.src"
                            alt="partner"
                            contain
                            class="partner-logo ma-4"
                        />
                    </div>
                </div>
            </div>
        </v-app>
    </div>
</template>
  
<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { apiObject } from "@/api/api";
import { mapGetters } from "vuex";
import { loginUser, getAuthUser } from "@/api/authenticationApi.js";
import EmailField from "@/components/EmailField.vue";
import PasswordField from "@/components/PasswordField.vue";

export default {
    name: "LogUser",

    components: {
        EmailField,
        PasswordField,
    },

    data() {
        return {
            partnerLogo: [
                { src: require("@/assets/partners/parter-logo-1.jpg") },
                { src: require("@/assets/partners/parter-logo-2.png") },
                { src: require("@/assets/partners/parter-logo-3.jpg") },
                { src: require("@/assets/partners/parter-logo-4.png") },
                { src: require("@/assets/partners/parter-logo-5.png") },
                { src: require("@/assets/partners/parter-logo-6.jpg") }
            ],
            errorMessage: "",
            error: false,
            email: null,
            password: null,
            validEmail: null,
            validPassword: null,
            screenWidth: null,
            loginUser: apiStatus.Idle,
            getAuthUser: apiStatus.Idle,
            hideForFirstRelease: true,
        };
    },

    computed: {
        ...mapGetters(["getUserRole"]),

        handleResize() {
            this.screenWidth = window.innerWidth;
        },

        invalidForm() {
            return !(
                this.email !== null &&
                this.password !== null &&
                this.validEmail === true &&
                this.validPassword
            );
        },
        ...apiStatusComputed(["loginUser", "getAuthUser"]),
    },

    methods: {
        async login() {
            const payload = {
                email: this.email,
                password: this.password,
            };
            this.loginUser = apiStatus.Pending;

            const { response, error } = await withAsync(loginUser, payload);

            if (error) {
                console.log(error);
                this.loginUser = apiStatus.Error;
                this.error = true;
                this.errorMessage =
                    error.response.data.metadata.response_message;
                this.$emit("error-message", this.errorMessage);
                return this.errorMessage;
            }

            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.data.token}`;

            const token = response.data.data.token;

            this.$store.dispatch("setToken", token);

            this.loginUser = apiStatus.Success;
            this.getUser();
        },

        // After login we retrieve all the info of the user
        async getUser() {
            this.getAuthUser = apiStatus.Pending;

            const { response, error } = await withAsync(getAuthUser);

            if (error) {
                console.log(error);
                this.getAuthUser = apiStatus.Error;
                this.error = true;
                this.errorMessage = error.response.data.message;
                return this.errorMessage;
            }

            this.getAuthUser = apiStatus.Success;
            let apiResponse = response.data.data;
            this.$store.dispatch("setCurrentUser", apiResponse.name);
            this.$store.dispatch("setCurrentUserData", apiResponse);
            this.$router.replace("/admin-panel");
        },
    },

    created() {
        this.screenWidth = window.innerWidth;
        window.addEventListener("resize", this.handleResize);
    },

    updated() {
        this.screenWidth = window.innerWidth;
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped lang="scss">
@import "@/plugins/login.scss";

.form-label {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
}
.form-img {
    width: 50%;
    height: 100%;
}
.logo {
    color: #19a7ce;
    font-family: "Rubik", sans-serif;
    font-size: 80px;
}
.forgotten-text {
    color: #19a7ce;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
}

.off {
    color: #19a7ce;
}

.partner-logo{
    max-width: 160px;
    // min-width: 100px;
    width: 100%;
    height: auto;

}
</style>